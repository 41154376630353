import { UserIcon } from '@gain/components/icons'
import { EmailAddressSourceType } from '@gain/rpc/app-model'
import { formatAssetManager } from '@gain/utils/asset'
import { useSplitList } from '@gain/utils/list'
import Stack from '@mui/material/Stack'
import { useState } from 'react'

import ContentLink from '../../../../common/content-link'
import LinkedInButton from '../../../../common/linked-in-button/linked-in-button'
import ViewMoreButton from '../../../../common/view-more-button'
import VirtualTable, { createVirtualTableColumns } from '../../../../common/virtual-table'
import RequestEmailButton from '../../../../features/request-email-button/request-email-button'
import { Manager } from './asset-management-util'
import ManagerRoleCell from './manager-role-cell'

const columns = createVirtualTableColumns<Manager>(
  {
    field: 'person',
    headerName: 'Name',
    width: 216,
    sortable: false,
    renderCell: ({ row }) => {
      return (
        <ContentLink
          avatarProps={{
            children: <UserIcon />,
          }}
          label={formatAssetManager(row)}
          labelTypographyProps={{ disableTooltip: false }}
          region={row.person?.locationRegion}
        />
      )
    },
  },
  {
    field: 'investor',
    width: 140,
    flex: 1,
    sortable: false,
    headerName: 'Role / Investor',
    renderCell: ({ row }) => <ManagerRoleCell manager={row} />,
  },
  {
    field: 'person',
    width: 96,
    sortable: false,
    align: 'right',
    renderCell: ({ row }) => {
      if (!row.person) {
        return null
      }

      let sourceType = EmailAddressSourceType.Asset
      let sourceId = row.assetId
      if (row.investor) {
        sourceType = EmailAddressSourceType.Investor
        sourceId = row.investor.id
      }

      return (
        <Stack
          alignItems={'center'}
          flexDirection={'row'}
          gap={0.25}
          justifyContent={'end'}
          width={'100%'}>
          <RequestEmailButton
            objectId={sourceId}
            objectType={sourceType}
            person={row.person}
          />
          <LinkedInButton url={row.person.linkedInUrl} />
        </Stack>
      )
    },
  }
)

interface ManagementTableProps {
  managers: Manager[]
}

export default function ManagementTable({ managers }: ManagementTableProps) {
  const [showAll, setShowAll] = useState(false)
  const [initialItems, additionalItems] = useSplitList(managers, 3)

  return (
    <>
      <VirtualTable
        columns={columns}
        RowComponentProps={() => ({
          hover: false,
        })}
        rows={showAll ? managers : initialItems}
        variant={'inline'}
      />
      {additionalItems.length > 0 && !showAll && (
        <ViewMoreButton
          amount={additionalItems.length}
          onClick={() => setShowAll(true)}
          variant={'chevron-down'}
        />
      )}
    </>
  )
}
