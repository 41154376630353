import { Asset } from '@gain/rpc/app-model'
import { useSplitList, useVisibleListItems } from '@gain/utils/list'
import { useVisibleColumns } from '@gain/utils/table'
import React, { useEffect, useRef } from 'react'

import Card, { CardHeader, CardProps } from '../../../../common/card/card'
import Table from '../../../../common/table'
import ViewMoreButton from '../../../../common/view-more-button'
import { useAssetCompetitors } from '../../use-asset-competitors'
import { assetCompetitorsColumns, columnVisibility } from './competitors-card-hooks'

export interface CompetitorsCardProps extends CardProps {
  asset: Asset
  onResizeHeight?: () => void
}

export default function CompetitorsCard({
  asset,
  onResizeHeight,
  ...cardProps
}: CompetitorsCardProps) {
  const tableRef = useRef<HTMLDivElement>(null)

  const swrAssetCompetitors = useAssetCompetitors(asset.competitors)

  const [initialAssets, additionalAssets] = useSplitList(swrAssetCompetitors.data.items, 8)
  const [rows, showAll, handleShowAll] = useVisibleListItems(initialAssets, additionalAssets)

  // Let containing component know when the height of this card changes.
  useEffect(() => {
    onResizeHeight && onResizeHeight()
  }, [onResizeHeight, showAll, swrAssetCompetitors.data.items.length])

  const visibleColumns = useVisibleColumns(tableRef, assetCompetitorsColumns, columnVisibility)

  return (
    <Card {...cardProps}>
      <CardHeader
        explainer={'This list has been hand-picked by our research team.'}
        title={'Curated competitor'}
      />

      <Table
        ref={tableRef}
        columns={visibleColumns}
        rows={rows}
        disablePaddingBottomOnLastRow
      />

      {!showAll && (
        <ViewMoreButton
          amount={additionalAssets.length}
          onClick={handleShowAll}
          variant={'chevron-down'}
        />
      )}
    </Card>
  )
}
