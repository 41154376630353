import Head from '@gain/modules/head'
import { Asset } from '@gain/rpc/app-model'
import { AssetProfileType } from '@gain/rpc/shared-model'
import { useElementWidth } from '@gain/utils/dom'
import Grid from '@mui/material/Grid'
import React, { useRef } from 'react'

import CardAssetFte, { useHasFteMeasurements } from '../../../features/asset/asset-fte-card'
import { useAssetPageParams } from '../../utils'
import { AssetAutomatedRequestDetailed } from '../asset-automated'
import { shouldShowFinancialsTab } from '../asset-financials'
import AssetAssessmentCard from './asset-assessment-card'
import AssetBusinessCard from './asset-business-card'
import AssetFinancialsCard from './asset-financials-card'
import AssetFurtherContextCard from './asset-further-context-card'
import AssetMarketCard from './asset-market-card'
import AssetNewsCard from './asset-news-card'
import AssetOwnershipCard from './asset-ownership-card'
import {
  useAssetConferences,
  useAssetIndustries,
  useListAssetArticles,
} from './asset-summary-hooks'
import ShareFeedbackAlert from './share-feedback-alert'

interface AssetSummaryProps {
  asset: Asset
}

export default function AssetSummary({ asset }: AssetSummaryProps) {
  const ref = useRef<HTMLDivElement>(null)
  const width = useElementWidth(ref)
  const params = useAssetPageParams()
  const articles = useListAssetArticles(params.id)
  const industries = useAssetIndustries(asset)
  const conferences = useAssetConferences(asset)

  const isHorizontalLayout = width > 951

  const hasArticles = !articles.loading && !articles.empty
  const hasIndustries = industries && industries.items.length > 0
  const hasConferences = conferences && conferences.length > 0
  const hasFurtherContext = hasIndustries || hasConferences
  const hasArticlesOrFurtherContext = articles.data.items.length > 0 || hasFurtherContext
  const hasFteMeasurements = useHasFteMeasurements(asset)

  const hasFinancialResults = shouldShowFinancialsTab(asset)
  const showFteInsteadOfFinancialsForAutomatedAssets =
    asset.profileType === AssetProfileType.Automated && hasFteMeasurements && !hasFinancialResults

  return (
    <>
      <Head>
        <title>{asset.generalInfo?.name} - Summary</title>
      </Head>
      <Grid
        ref={ref}
        spacing={2}
        container>
        {isHorizontalLayout && asset.profileType === AssetProfileType.Automated && (
          <Grid
            xs={12}
            item>
            <AssetAutomatedRequestDetailed asset={asset} />
          </Grid>
        )}

        <Grid
          xs={12}
          item>
          <Grid
            alignItems={'stretch'}
            direction={'row'}
            spacing={2}
            container>
            <Grid
              lg={4}
              xs={12}
              item>
              <Grid sx={{ height: '100%' }}>
                <AssetBusinessCard
                  asset={asset}
                  sx={{
                    minHeight: {
                      lg: showFteInsteadOfFinancialsForAutomatedAssets ? 678 : 604,
                    },
                  }}
                />
              </Grid>
            </Grid>
            {!isHorizontalLayout && asset.profileType === AssetProfileType.Automated && (
              <Grid
                xs={12}
                item>
                <AssetAutomatedRequestDetailed asset={asset} />
              </Grid>
            )}
            <Grid
              lg={8}
              spacing={2}
              xs={12}
              container
              item>
              <Grid
                md={6}
                xs={12}
                item>
                {showFteInsteadOfFinancialsForAutomatedAssets ? (
                  <CardAssetFte
                    asset={asset}
                    showDivider
                  />
                ) : (
                  <AssetFinancialsCard
                    asset={asset}
                    sx={{
                      minHeight: {
                        md: 292,
                      },
                    }}
                    fullHeight
                  />
                )}
              </Grid>
              <Grid
                md={6}
                xs={12}
                item>
                <AssetOwnershipCard
                  asset={asset}
                  sx={{
                    minHeight: {
                      md: 292,
                    },
                  }}
                  fullHeight
                />
              </Grid>
              <Grid
                md={6}
                xs={12}
                item>
                <AssetMarketCard
                  asset={asset}
                  sx={{
                    minHeight: {
                      md: 292,
                    },
                  }}
                  fullHeight
                />
              </Grid>
              <Grid
                md={6}
                xs={12}
                item>
                <AssetAssessmentCard
                  asset={asset}
                  sx={{
                    minHeight: {
                      md: 292,
                    },
                  }}
                  fullHeight
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {hasArticlesOrFurtherContext && (
          <>
            {hasArticles && (
              <Grid
                lg={hasFurtherContext ? 8 : 12}
                xs={12}
                item>
                <AssetNewsCard
                  articles={articles.data.items}
                  fullHeight
                />
              </Grid>
            )}
            {hasFurtherContext && (
              <Grid
                lg={hasArticles ? 4 : 12}
                xs={12}
                item>
                <AssetFurtherContextCard
                  conferences={conferences || []}
                  industries={industries?.items || []}
                  fullHeight
                />
              </Grid>
            )}
          </>
        )}

        <Grid
          xs={12}
          item>
          <ShareFeedbackAlert
            assetId={asset.id}
            assetName={asset.generalInfo?.name || ''}
            assetRegion={asset.generalInfo?.headquarters || ''}
          />
        </Grid>
      </Grid>
    </>
  )
}
