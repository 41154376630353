import { UserIcon } from '@gain/components/icons'
import { EmailAddressSourceType } from '@gain/rpc/app-model'
import { formatAssetManager } from '@gain/utils/asset'
import { formatManagerPositionSince } from '@gain/utils/manager'

import LinkedInButton from '../../../../common/linked-in-button/linked-in-button'
import ListItem from '../../../../common/list/list-item'
import RequestEmailButton from '../../../../features/request-email-button/request-email-button'
import { generateInvestorPagePath } from '../../../utils'
import { Manager } from './asset-management-util'

function formatSecondaryLine(manager: Manager) {
  if (manager.investor) {
    return manager.investor.name
  }

  return formatManagerPositionSince(manager.managementPosition, manager.joinYear, manager.joinMonth)
}

interface ManagementListItemProps {
  manager: Manager
}

export default function ManagementListItem({ manager }: ManagementListItemProps) {
  // Gather required props for `RequestEmailButton`
  let sourceType = EmailAddressSourceType.Asset
  let sourceId = manager.assetId
  if (manager.investor) {
    sourceType = EmailAddressSourceType.Investor
    sourceId = manager.investor.id
  }

  return (
    <ListItem
      action={
        <>
          {manager.person && (
            <RequestEmailButton
              objectId={sourceId}
              objectType={sourceType}
              person={manager.person}
            />
          )}
          <LinkedInButton url={manager.person?.linkedInUrl} />
        </>
      }
      avatarProps={{
        children: <UserIcon />,
      }}
      href={
        !!manager.person?.investorId &&
        generateInvestorPagePath({
          investorId: manager.person.investorId,
          investorName: manager.investor?.name || '-',
        })
      }
      primary={formatAssetManager(manager)}
      region={manager.person?.locationRegion}
      secondary={formatSecondaryLine(manager)}
    />
  )
}
