import { useAsset, useTrackVisit } from '@gain/api/app/hooks'
import { CompanyIcon } from '@gain/components/icons'
import { useIsBrowserExtension } from '@gain/modules/browser-extension'
import { Asset, ObjectType } from '@gain/rpc/app-model'
import { AssetProfileType } from '@gain/rpc/shared-model'
import { isJsonRpcError, RpcErrorCode } from '@gain/rpc/utils'
import { useVisibleContainerHeight } from '@gain/utils/dom'
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import { styled, Theme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { ReactElement, useCallback, useEffect } from 'react'
import { Redirect, Route, Switch, useHistory } from 'react-router-dom'

import Loading from '../../common/loading'
import ProfileTabBar, { ProfileTabContainer, useActiveTab } from '../../common/profile-tab-bar'
import BookmarksListAddAssetButton from '../../features/bookmarks-list/bookmarks-list-add-assets/bookmarks-list-add-asset-button'
import ButtonAddToDealCloud from '../../features/dealcloud'
import { useTrackEvent } from '../../features/google-analytics'
import { useTrackPageView } from '../../features/planhat/use-track-activity'
import MobilePageHeader from '../../layout/mobile-page-header'
import {
  ASSET_PAGE_ADVISORS_PATH,
  ASSET_PAGE_ASSESSMENT_PATH,
  ASSET_PAGE_BENCHMARKING_PATH,
  ASSET_PAGE_BUSINESS_PATH,
  ASSET_PAGE_FINANCIALS_PATH,
  ASSET_PAGE_MARKET_PATH,
  ASSET_PAGE_OWNERSHIP_PATH,
  ASSET_PAGE_SOURCES_PATH,
  ASSET_PAGE_SUMMARY_PATH,
  ASSET_PAGE_VALUATION_PATH,
  generateAssetPagePath,
  useAssetPageParams,
} from '../utils'
import AssetAdvisorsTab from './asset-advisors-tab'
import AssetAssessment from './asset-assessment'
import AssetAutomated from './asset-automated'
import AssetBenchmarking from './asset-benchmarking/asset-benchmarking'
import AssetBusiness from './asset-business'
import AssetFinancials from './asset-financials'
import AssetHeaderDownloads from './asset-header/asset-header-downloads'
import AssetHeaderSubtitle, { useHasSubtitle } from './asset-header/asset-header-subtitle'
import AssetMarket from './asset-market'
import AssetOffline from './asset-offline/asset-offline'
import AssetOwnership from './asset-ownership'
import AssetSources from './asset-sources/asset-sources'
import AssetSummary from './asset-summary'
import AssetValuation from './asset-valuation'
import { useAssetPageTabs } from './route-asset-hooks'

const StyledProfileTabContainer = styled(ProfileTabContainer)({
  flex: 1,
  alignItems: 'flex-start',
  display: 'flex',
  flexDirection: 'column',
})

export interface RouteAssetProps {
  /**
   * Used in the browser extension so we can add the banner "View in Gain.pro"
   */
  onAssetFetched?: (asset: Asset) => void
}

export default function RouteAsset({ onAssetFetched }: RouteAssetProps) {
  const [containerRef, availableHeight] = useVisibleContainerHeight<HTMLDivElement>()
  const params = useAssetPageParams()
  const trackEvent = useTrackEvent()
  const isXs = useMediaQuery<Theme>((theme) => theme.breakpoints.only('xs'))
  const isMdDown = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'))

  const history = useHistory()
  const isBrowserExtension = useIsBrowserExtension()

  useTrackVisit(params.id, ObjectType.Asset)

  const {
    data: asset,
    loading,
    error,
  } = useAsset({
    assetId: params.id,
    preview: params.preview,
  })

  const tabs = useAssetPageTabs(asset)
  const activeTab = useActiveTab(tabs)
  useTrackPageView('asset', {
    id: params.id,
    tab: activeTab,
    requireTab: true,
    country: asset?.generalInfo?.headquarters,
  })
  const hasSubtitle = useHasSubtitle(asset)

  const handleLogoClick = useCallback(() => {
    if (activeTab !== 'summary') {
      history.push(generateAssetPagePath(params, ASSET_PAGE_SUMMARY_PATH))
    }
  }, [activeTab, history, params])

  // Track that an asset in the asset region has been viewed
  useEffect(() => {
    if (asset && asset?.generalInfo?.headquarters) {
      trackEvent('VIEW_ASSET_IN_REGION', 'ASSET_VIEW', undefined, undefined, {
        country_code: asset.generalInfo.headquarters,
      })
    }
  }, [asset, trackEvent])

  useEffect(() => {
    if (asset && onAssetFetched) {
      onAssetFetched(asset)
    }
  }, [asset, onAssetFetched])

  if (loading) {
    return <Loading />
  }

  if (error) {
    if (isJsonRpcError(error) && error.code === RpcErrorCode.ResourceOffline) {
      return (
        <AssetOffline
          id={params.id}
          title={params.name || null}
        />
      )
    }

    // Re-trow error so it will be handled by an Ancestor ErrorBoundary component
    throw error
  }

  if (!asset) {
    return null
  }

  if (asset.profileType === AssetProfileType.Automated && tabs.length === 0) {
    return (
      <AssetAutomated
        asset={asset}
        isBrowserExtension={isBrowserExtension}
      />
    )
  }

  return (
    <>
      {isXs && !isBrowserExtension && (
        <MobilePageHeader
          actions={
            <BookmarksListAddAssetButton
              assetId={asset.id}
              color={'default'}
              fontSize={24}
              bold
            />
          }
          title={asset.generalInfo?.name}
        />
      )}

      <ProfileTabBar
        activeTab={activeTab}
        defaultIcon={CompanyIcon}
        infoActions={
          [
            <BookmarksListAddAssetButton
              key={'btn-add-to-list'}
              assetId={asset.id}
            />,
            !isBrowserExtension && (
              <AssetHeaderDownloads
                key={'btn-downloads'}
                asset={asset}
              />
            ),
            <ButtonAddToDealCloud
              key={'btn-add-to-dealcloud'}
              assetId={asset.id}
              variant={isBrowserExtension || isMdDown ? 'icon' : 'contained'}
            />,
          ].filter(Boolean) as ReactElement[]
        }
        logoFileUrl={asset.generalInfo?.logoFileUrl || undefined}
        onLogoClick={handleLogoClick}
        subtitle={hasSubtitle ? <AssetHeaderSubtitle asset={asset} /> : undefined}
        tabs={tabs}
        title={
          <Stack
            direction={'row'}
            gap={1}>
            {asset.generalInfo?.name || params.name || ''}
            {asset.profileType === AssetProfileType.Automated && (
              <Chip
                color={'warning'}
                label={'Generative AI'}
              />
            )}
          </Stack>
        }
        replaceUrl
        sticky
      />

      <StyledProfileTabContainer
        ref={containerRef}
        maxWidth={'lg'}
        stickyVariant={tabs.length > 0 ? 'tabs' : 'no-tabs'}
        disableGuttersTop>
        <Switch>
          <Route
            path={ASSET_PAGE_SUMMARY_PATH}
            render={() => <AssetSummary asset={asset} />}
            exact
          />
          <Route
            path={ASSET_PAGE_ASSESSMENT_PATH}
            render={() => <AssetAssessment asset={asset} />}
            exact
          />
          <Route
            path={ASSET_PAGE_FINANCIALS_PATH}
            render={() => <AssetFinancials asset={asset} />}
            exact
          />
          <Route
            path={ASSET_PAGE_VALUATION_PATH}
            render={() => <AssetValuation asset={asset} />}
            exact
          />
          <Route
            path={ASSET_PAGE_BUSINESS_PATH}
            render={() => <AssetBusiness asset={asset} />}
            exact
          />
          <Route
            path={ASSET_PAGE_MARKET_PATH}
            render={() => <AssetMarket asset={asset} />}
            exact
          />
          <Route
            path={ASSET_PAGE_OWNERSHIP_PATH}
            render={() => <AssetOwnership asset={asset} />}
            exact
          />
          <Route
            path={ASSET_PAGE_ADVISORS_PATH}
            render={() => (
              <AssetAdvisorsTab
                asset={asset}
                availableHeight={availableHeight}
              />
            )}
            exact
          />
          <Route
            path={ASSET_PAGE_SOURCES_PATH}
            render={() => <AssetSources asset={asset} />}
            exact
          />
          <Route
            path={ASSET_PAGE_BENCHMARKING_PATH}
            render={() => <AssetBenchmarking asset={asset} />}
            exact
          />
          <Route path={'/'}>
            <Redirect
              to={generateAssetPagePath(
                {
                  id: params.id,
                  name: params.name,
                  preview: params.preview,
                },
                ASSET_PAGE_SUMMARY_PATH
              )}
            />
          </Route>
        </Switch>
      </StyledProfileTabContainer>
    </>
  )
}
