import { CompanyIcon } from '@gain/components/icons'
import Tooltip from '@gain/components/tooltip'
import { AssetListItem } from '@gain/rpc/app-model'
import { styled } from '@mui/material/styles'

import ConditionalWrapper from '../../../common/conditional-wrapper'
import ContentLink, { ContentLinkProps } from '../../../common/content-link'
import { generateAssetPagePath } from '../../../routes/utils'
import TooltipAsset from '../asset-tooltip'

const StyledContainer = styled('div')(({ theme }) => ({
  display: 'inline-flex',
  minWidth: 0,
}))

interface AssetContentLinkProps
  extends Pick<ContentLinkProps, 'description' | 'variant' | 'endIcon' | 'showBadge'> {
  id?: number | null
  name?: string | null
  logoFileUrl?: string | null
  asset?: AssetListItem | null
  region?: string | null
  disableTooltip?: boolean
}

export default function AssetContentLink({
  id,
  logoFileUrl: logoFileUrlProp,
  asset,
  disableTooltip,
  ...props
}: AssetContentLinkProps) {
  const name = props.name || asset?.name || '-'
  const logoFileUrl = logoFileUrlProp || asset?.logoFileUrl

  let href: string | null = null
  if (typeof id === 'number') {
    href = generateAssetPagePath({ id, name })
  }

  return (
    <ConditionalWrapper
      condition={!!id}
      wrapper={(children) => (
        <TooltipAsset
          // asset={asset} TODO: remove? see slack
          disableTooltip={disableTooltip}
          id={id}>
          <StyledContainer>{children}</StyledContainer>
        </TooltipAsset>
      )}
      wrapperWhenFalse={(children) => (
        <Tooltip title={name}>
          <StyledContainer>{children}</StyledContainer>
        </Tooltip>
      )}>
      <ContentLink
        avatarProps={{
          src: logoFileUrl || undefined,
          children: !logoFileUrl && <CompanyIcon />,
        }}
        href={href}
        label={name}
        labelTypographyProps={{
          disableTooltip: true,
        }}
        {...props}
      />
    </ConditionalWrapper>
  )
}
