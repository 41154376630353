import Head from '@gain/modules/head'
import { Asset } from '@gain/rpc/app-model'
import { isTruthy } from '@gain/utils/common'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'

import CardDescription from '../../../common/card/card-description'
import CardAssetFte, { useHasFteMeasurements } from '../../../features/asset/asset-fte-card'
import SourcesList from '../../../features/source/source-list'
import { useAssetSources } from '../route-asset-hooks'
import AssetBusinessDetailsCard, { useHasBusinessDetails } from './asset-business-details-card'
import AssetChartsCard from './asset-charts-card'
import AssetSegmentationCard from './asset-segmentation-card'

interface AssetBusinessProps {
  asset: Asset
}

export default function AssetBusiness({ asset }: AssetBusinessProps) {
  const hasBusinessDetails = useHasBusinessDetails(asset)
  const hasFteMeasurements = useHasFteMeasurements(asset)
  const businessDescription = [
    ...(asset.description?.atAGlance ? asset.description.atAGlance.split('\n') : []),
    ...(asset.description?.furtherInformation
      ? asset.description.furtherInformation.split('\n')
      : []),
    asset.market?.competition,
  ]
    .filter(isTruthy)
    .join('\n\n')

  const hasDescription = businessDescription

  const sources = useAssetSources(asset.sources, 'business')

  return (
    <>
      <Head>
        <title>{asset.generalInfo?.name} - Business</title>
      </Head>
      <Grid
        spacing={2}
        container>
        {(hasBusinessDetails || hasDescription) && (
          <Grid
            spacing={2}
            container
            item>
            {hasBusinessDetails && (
              <Grid
                lg={!hasDescription && hasFteMeasurements ? 6 : 12}
                xs={12}
                item>
                <AssetBusinessDetailsCard
                  asset={asset}
                  fullHeight
                />
              </Grid>
            )}
            {hasDescription && (
              <Grid
                md={hasFteMeasurements ? 6 : 12}
                xs={12}
                item>
                <Stack
                  gap={2}
                  height={'100%'}>
                  {businessDescription && (
                    <CardDescription
                      title={'Business description'}
                      fullHeight>
                      {businessDescription}
                    </CardDescription>
                  )}
                </Stack>
              </Grid>
            )}
            {hasFteMeasurements && (
              <Grid
                md={hasDescription ? 6 : 12}
                xs={12}
                item>
                <CardAssetFte
                  asset={asset}
                  showDivider
                />
              </Grid>
            )}
          </Grid>
        )}
        {!!asset.segmentation?.charts.length && (
          <Grid
            xs={12}
            item>
            <AssetChartsCard charts={asset.segmentation.charts} />
          </Grid>
        )}
        {!!asset.segmentation?.segments.length && (
          <Grid
            xs={12}
            item>
            <AssetSegmentationCard segmentation={asset.segmentation} />
          </Grid>
        )}
        {sources.length > 0 && (
          <Grid
            xs={12}
            item>
            <SourcesList sources={sources} />
          </Grid>
        )}
      </Grid>
    </>
  )
}
