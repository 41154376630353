import { IndustryMarketSegmentListItem } from '@gain/rpc/app-model'
import { useElementWidth } from '@gain/utils/dom'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import React, { useCallback, useRef, useState } from 'react'

import Card, { CardContent, CardHeader, CardTabs } from '../../../common/card/card'
import IndustryEsgAssessmentList from './industry-esg-assessment-list'
import IndustryEsgAssessmentTable from './industry-esg-assessment-table'

const StyledCard = styled(Card)(({ theme }) => ({
  paddingBottom: theme.spacing(3),
}))

export interface EsgAssessmentCardProps {
  description?: string
  segments: IndustryMarketSegmentListItem[]
  disableSubtitle?: boolean
  className?: string
}

export default function EsgAssessmentCard({
  description,
  disableSubtitle,
  segments,
  className,
}: EsgAssessmentCardProps) {
  const [activeSegmentIndex, setActiveSegmentIndex] = useState(0)
  const ref = useRef<HTMLDivElement>(null)
  const width = useElementWidth(ref)

  const handleSegmentChange = useCallback((event, value) => {
    setActiveSegmentIndex(value)
  }, [])

  const segment = React.useMemo(() => segments[activeSegmentIndex], [segments, activeSegmentIndex])

  return (
    <StyledCard
      ref={ref}
      className={className}>
      <CardHeader title={'ESG assessment'} />

      {description && (
        <CardContent>
          <Typography variant={'body2'}>{description}</Typography>
        </CardContent>
      )}

      {!disableSubtitle && (
        <CardContent>
          <Typography
            mb={1}
            mt={2}
            variant={'subtitle2'}>
            Industry assessment
          </Typography>
        </CardContent>
      )}

      {segments.length > 1 && (
        <CardTabs
          onChange={handleSegmentChange}
          options={segments.map(({ industryTitle }, index) => ({
            label: industryTitle || '',
            value: index,
          }))}
          value={activeSegmentIndex}
        />
      )}

      <CardContent>
        {!disableSubtitle && (
          <Typography
            mb={1}
            mt={2}
            variant={'subtitle2'}>
            Segment: {segment.name}
          </Typography>
        )}

        <Stack
          direction={'column'}
          mt={segments.length === 1 ? 1 : 0}
          spacing={3}>
          <Stack
            direction={'column'}
            spacing={1}>
            {width >= 888 && <IndustryEsgAssessmentTable segment={segment} />}
            {width < 888 && <IndustryEsgAssessmentList segment={segment} />}
          </Stack>
        </Stack>
      </CardContent>
    </StyledCard>
  )
}
